var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vca-row',[_c('h2',[_vm._v(_vm._s(_vm.current.full_name))]),_c('div',{staticClass:"vca-right"},[_c('div',{staticClass:"tags-container",class:{
        'tags-green': _vm.current.confirmed,
        'tags-orange': !_vm.current.confirmed,
      }},[(_vm.current.confirmed)?_c('div',{staticClass:"tag"},[_c('div',{staticClass:"tag-label"},[_vm._v(" "+_vm._s(_vm.$t("users.profile.confirmed.active"))+" ")])]):_c('div',{staticClass:"tag"},[_c('div',{staticClass:"tag-label"},[_vm._v(" "+_vm._s(_vm.$t("users.profile.confirmed.inactive"))+" ")]),(_vm.hasSystemPermission())?_c('div',{staticClass:"confirm",on:{"click":function($event){return _vm.confirmUser()}}},[_vm._v(" ✓ ")]):_vm._e()])]),(_vm.isActive)?_c('div',{staticClass:"tags-container tags-green"},[_c('div',{staticClass:"tag"},[_c('div',{staticClass:"tag-label"},[_vm._v(" "+_vm._s(_vm.$t("users.profile.active.active"))+" ")]),(
            _vm.hasPoolPermission('network') ||
            _vm.hasPoolPermission('operation') ||
            _vm.hasSystemPermission()
          )?_c('div',{staticClass:"cancel",on:{"click":function($event){return _vm.rejectActive()}}},[_vm._v(" ✕ ")]):_vm._e()])]):(_vm.isRequested)?_c('div',{staticClass:"tags-container tags-orange"},[_c('div',{staticClass:"tag"},[_c('div',{staticClass:"tag-label"},[_vm._v(" "+_vm._s(_vm.$t("users.profile.active.requested"))+" ")]),(
            _vm.hasPoolPermission('network') ||
            _vm.hasPoolPermission('operation') ||
            _vm.hasSystemPermission()
          )?_c('div',{staticClass:"confirm",on:{"click":function($event){return _vm.setActive()}}},[_vm._v(" ✓ ")]):_vm._e(),(
            _vm.hasPoolPermission('network') ||
            _vm.hasPoolPermission('operation') ||
            _vm.hasSystemPermission()
          )?_c('div',{staticClass:"cancel",on:{"click":function($event){return _vm.rejectActive()}}},[_vm._v(" ✕ ")]):_vm._e()])]):(_vm.hasSystemPermission() && _vm.current.crew.id != '')?_c('div',{staticClass:"tags-container tags-white"},[_c('div',{staticClass:"tag"},[_c('div',{staticClass:"tag-label"},[_vm._v(" "+_vm._s(_vm.$t("users.profile.active.inactive"))+" ")]),_c('div',{staticClass:"confirm",on:{"click":function($event){return _vm.setActive()}}},[_vm._v("✓")])])]):_vm._e(),(_vm.nvmActive)?_c('div',{staticClass:"tags-container tags-green"},[_c('div',{staticClass:"tag"},[_c('div',{staticClass:"tag-label"},[_vm._v(" "+_vm._s(_vm.$t("users.profile.nvm.confirmed"))+" ")]),(_vm.hasSystemPermission())?_c('div',{staticClass:"cancel",on:{"click":function($event){return _vm.removeNVM()}}},[_vm._v(" ✕ ")]):_vm._e()])]):(_vm.canBeNVM && _vm.hasSystemPermission())?_c('div',{staticClass:"tags-container tags-white"},[_c('div',{staticClass:"tag"},[_c('div',{staticClass:"tag-label"},[_vm._v(" "+_vm._s(_vm.$t("users.profile.nvm.inactive"))+" ")]),_c('div',{staticClass:"confirm",on:{"click":function($event){return _vm.setNVM()}}},[_vm._v("✓")])])]):_vm._e(),_c('div',{staticClass:"vertical-center"},[(_vm.hasSystemPermission('admin'))?_c('button',{staticClass:"vca-button-small",on:{"click":function($event){return _vm.syncUser()}}},[_vm._v(" "+_vm._s(_vm.$t("button.sync"))+" ")]):_vm._e()]),(_vm.hasSystemPermission())?_c('vca-cancel-button',{staticClass:"vertical-center",attrs:{"placeholder":_vm.$t('users.profile.delete.button')},on:{"click":function($event){return _vm.deleteUser()}}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }