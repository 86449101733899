<template>
  <vca-card class="shadowed">
    <h3>{{ $t("users.profile.crew.header") }}</h3>
    <vca-row>
      <h4 class="short">{{ $t("users.profile.crew.crew") }}</h4>
      <div>{{ current.crew.name }}</div>
    </vca-row>
    <vca-row v-if="userHasSystemPermission()">
      <h4 class="short">{{ $t("organisation.context") }}</h4>
      <div v-if="!hasSystemPermission('admin')">
        {{ current.organisation }}
      </div>
    </vca-row>
    <vca-row v-if="userHasSystemPermission() && hasSystemPermission('admin')">
      <vca-dropdown
        v-model="organisation"
        :options="organisationList"
        label=""
        :placeholder="$t('crews.organisation.placeholder')"
        :errorMsg="$t('crews.organisation.errorMsg')"
      ></vca-dropdown>
    </vca-row>
    <h3>
      {{ $t("users.profile.applications.header") }}
      {{ user_participations.length }}
    </h3>
    <vca-row>
      <div>
        <span class="bold">{{
          $t("users.profile.applications.confirmed")
        }}</span>
        {{ getParticipation("confirmed") }}
      </div>
      <div>
        <span class="bold">{{
          $t("users.profile.applications.rejected")
        }}</span>
        {{ getParticipation("rejected") }}
      </div>
    </vca-row>
    <vca-row>
      <div>
        <span class="bold">{{
          $t("users.profile.applications.requested")
        }}</span>
        {{ getParticipation("requested") }}
      </div>
      <div>
        <span class="bold">{{
          $t("users.profile.applications.withdrawn")
        }}</span>
        {{ getParticipation("withdrawn") }}
      </div>
    </vca-row>
    <h4>{{ $t("users.profile.crew.roles") }}</h4>

    <div
      v-if="current.pool_roles && availableRoles.length > 0"
      class="tags-container tags-white"
    >
      <div
        class="tag"
        :key="res.name"
        v-for="res in availableRoles"
        :class="{ role_added: isAdded(res.value) }"
      >
        <div class="tag-label">{{ res.name }}</div>
        <div
          class="confirm"
          v-if="
            hasSystemPermission() ||
            hasPoolPermission('network') ||
            hasPoolPermission(res.value)
          "
        >
          <input
            type="checkbox"
            class="role_checkbox"
            v-model="addedRoles"
            :value="res.value"
          />
        </div>
      </div>
    </div>
    <div v-else-if="current.pool_roles == 0">
      {{ $t("users.profile.nvm.missing") }}
    </div>

    <div v-if="current.pool_roles" class="tags-container tags-blue">
      <div class="tag" :key="res.name" v-for="res in current.pool_roles">
        <div class="tag-label">{{ poolRoleLabel(res) }}</div>
        <div
          class="cancel"
          v-if="
            hasSystemPermission() ||
            hasPoolPermission('network') ||
            hasPoolPermission(res.name)
          "
        >
          <input
            type="checkbox"
            class="role_checkbox"
            v-model="currentRoles"
            :value="res.name"
          />
        </div>
      </div>
    </div>
    <div v-if="availableRoles != 0 || current.pool_roles != 0">
      <button class="vca-button-small" @click="updateRoles">
        {{ $t("context_button.update", { 0: $t("context.roles") }) }}
      </button>
    </div>

    <h3>{{ $t("users.profile.system.roles") }}</h3>
    <div v-if="current.system_roles" class="tags-container">
      <div class="tag" :key="res.name" v-for="res in current.system_roles">
        <div class="tag-label">{{ roleLabel(res) }}</div>
      </div>
    </div>
  </vca-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ProfileBulkRoles",
  data() {
    return {
      addedRoles: [],
      currentRoles: [],
      user_participations: [],
      // TODO Change to roles from backend
      allRoles: [
        {
          name: this.$t("network.roles.operation"),
          value: "operation",
        },
        { name: this.$t("network.roles.finance"), value: "finance" },
        {
          name: this.$t("network.roles.education"),
          value: "education",
        },
        { name: this.$t("network.roles.network"), value: "network" },
        {
          name: this.$t("network.roles.socialmedia"),
          value: "socialmedia",
        },
        {
          name: this.$t("network.roles.awareness"),
          value: "awareness",
        },
        { name: this.$t("network.roles.other"), value: "other" },
      ],
    };
  },
  created() {
    this.setCurrentRoles();
    this.$store.dispatch("organisations/list");
    let data = { user_id: this.current.id };
    this.$store
      .dispatch({ type: "events/participations/queryList", data: data })
      .then((response) => {
        this.user_participations =
          !response || response.length == 0 ? [] : response;
      });
  },
  methods: {
    roleLabel(role) {
      return this.$t("system_roles." + role.name);
    },
    poolRoleLabel(role) {
      return this.$t("users.list.roles." + role.name);
    },
    setCurrentRoles() {
      this.currentRoles = this.current.pool_roles.map((el) => el.name);
    },
    isAdded(role) {
      return this.addedRoles.includes(role);
    },
    updateRoles() {
      if (!confirm(this.$t("messages.role.selection_confirm"))) {
        return;
      }
      const deletedRoles = [];
      this.current.pool_roles.forEach((role) => {
        if (!this.currentRoles.includes(role.name)) {
          deletedRoles.push({
            user_id: this.current.id,
            role: role.name,
          });
        }
      });
      this.$store
        .dispatch({
          type: "users/roles/bulk",
          data: {
            crew_id: this.current.crew_id,
            created: this.addedRoles.map((role) => {
              return {
                user_id: this.current.id,
                role: role,
              };
            }),
            removed: deletedRoles,
          },
        })
        .then(() => {
          this.addedRoles = [];
          this.$store.dispatch({ type: "refresh" });
          if (this.hasSystemPermission()) {
            this.$store.dispatch({ type: "users/list" }).then((response) => {
              this.current = response.find((el) => this.current.id == el.id);
              this.setCurrentRoles();
            });
            this.$store.dispatch({ type: "crews/public" });
          } else if (this.hasPoolPermission()) {
            let data = { crew_id: this.user.crew.crew_id };
            this.$store
              .dispatch({ type: "users/queryList", data: data })
              .then((response) => {
                this.$store.commit("users/list", response);
                this.current = response.find((el) => this.current.id == el.id);
                this.setCurrentRoles();
              });
          }
        });
    },
    getParticipation(state) {
      return this.user_participations.filter((el) => {
        return el.status == state;
      }).length;
    },
  },
  computed: {
    organisation: {
      set(value) {
        let id = value.length ? value[0].value : null;
        if (id) {
          if (id != this.current.organisation_id) {
            if (!confirm(this.$t("messages.user.organisation.confirm"))) {
              return;
            }
            this.current.organisation_id = id;
            this.$store.dispatch({ type: "users/update_organisation" });
          }
        } else {
          this.notification({
            title: this.$t("messages.title.hint"),
            body: this.$t("messages.organisation.not_deleteable"),
            type: "error",
          });
        }
      },
      get() {
        return this.$store.state.organisations.list.filter((el) => {
          el.title = el.name;
          el.label = el.name;
          el.value = el.id;
          return el.id == this.current.organisation_id;
        });
      },
    },
    organisationList: {
      get() {
        return this.$store.state.organisations.list.filter((el) => {
          el.title = el.name;
          el.label = el.name;
          el.value = el.id;
          return true;
        });
      },
    },
    availableRoles() {
      if (
        (!this.hasPoolPermission() && !this.hasSystemPermission()) ||
        this.current.nvm.status != "confirmed"
      ) {
        return [];
      } else {
        let all = this.allRoles.filter((el) => {
          // Edited User doesnt have the role AND
          //  (Logged in user has pool permission to set it)

          // Role is not available if the user currently has the role
          if (
            this.current.pool_roles &&
            this.current.pool_roles.find((element) => element.name == el.value)
          ) {
            return false;
          }
          // If the logged in user has system permission OR network pool role OR the current role, it is available
          return this.hasSystemPermission() || this.hasPoolPermission(el.value);
        });
        return all;
      }
    },
    current: {
      get() {
        return this.$store.state.users.current;
      },
      set(value) {
        this.$store.commit("users/current", value);
      },
    },
    ...mapGetters({
      user: "user/current",
      hasPoolPermission: "user/roles/hasPoolPermission",
      hasSystemPermission: "user/roles/hasSystemPermission",
      userHasSystemPermission: "users/userHasSystemPermission",
    }),
  },
};
</script>
<style lang="scss" scoped>
.vca-button-small {
  margin: 2px auto;
}
.role_checkbox {
  accent-color: #008fc3;
}
.role_added {
  background-color: rgba(0, 143, 196, 0.2) !important;
}
</style>
