<template>
  <div class="table-container">
    <VcaTable
      store="users"
      :list="pg_list"
      :colspan="9"
      :searchLabel="$t('users.list.search')"
    >
      <template v-slot:colspan>
        <col width="15%" />
        <col width="10%" />
        <col width="15%" />
        <col width="15%" />
        <col width="5%" />
        <col width="5%" />
        <col width="5%" />
        <col width="5%" />
        <col width="1%" />
      </template>

      <template v-slot:desktop-filter>
        <vca-accordion :header="$t('users.list.filter')">
          <div slot="body">
            <UsersFilter v-model="filter" @reset="resetFilter()" />
          </div>
        </vca-accordion>
      </template>

      <template v-slot:desktop-header>
        <th class="vca-table-cell sortable" @click="sort('full_name')">
          <label> {{ $t("users.list.name") }} &varr;</label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('modified.created')">
          <label> {{ $t("users.list.since") }} &varr;</label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('crew.email')">
          <label>{{ $t("users.list.crew_name") }} &varr;</label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('email')">
          <label>{{ $t("users.list.email") }} &varr;</label>
        </th>
        <th
          class="vca-table-cell sortable"
          @click="sort('profile.mattermost_username')"
        >
          <label>{{ $t("users.list.mattermost") }} &varr;</label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('active.status')">
          <label>{{ $t("users.list.active") }} &varr;</label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('nvm.status')">
          <label>{{ $t("users.list.nvm.short") }} &varr;</label>
        </th>
        <th
          class="vca-table-cell sortable"
          @click="sort('profile.birthdate_datetime')"
        >
          <label>{{ $t("users.list.age") }} &varr;</label>
        </th>
        <th class="vca-table-cell">
          <label>{{ $t("table.options") }}</label>
        </th>
      </template>

      <template v-slot:desktop-content>
        <tr
          class="vca-table-row"
          :class="{ last: index + 1 == pg.pageSize }"
          v-for="(res, index) in pg_list"
          :key="index"
        >
          <td class="vca-table-cell">
            <label>
              {{ res.full_name }}
              <span v-if="res.profile.display_name"
                >({{ res.profile.display_name }})</span
              >
            </label>
          </td>
          <td class="vca-table-cell">
            <label> {{ date(res.modified.created) }} </label>
          </td>
          <td class="vca-table-cell">
            <label> {{ res.crew.name }} </label>
          </td>
          <td class="vca-table-cell">
            <label> {{ res.email }} </label>
          </td>
          <td class="vca-table-cell">
            <label> {{ res.profile.mattermost_username }} </label>
          </td>
          <td class="vca-table-cell">
            <label v-if="res.active.status">
              {{ $t("users.list.active_state." + res.active.status) }}
            </label>
            <label v-else> - </label>
          </td>
          <td class="vca-table-cell">
            <label v-if="res.nvm.status">
              {{ $t("users.list.nvm_state." + res.nvm.status) }}
            </label>
            <label v-else> - </label>
          </td>
          <td class="vca-table-cell">
            <label> {{ getAge(res.profile.birthdate) }} </label>
          </td>
          <td class="vca-table-cell">
            <label class="table-options">
              <img
                v-if="hasSystemPermission('admin')"
                @click="setCurrentEdit(res)"
                class="editable"
                src="@/assets/icons/edit.png"
                :title="$t('button.edit')"
                :alt="$t('button.edit')"
              />
              <img
                @click="setCurrent(res)"
                class="editable apply-filter"
                src="@/assets/icons/public.svg"
                :title="$t('button.view')"
                :alt="$t('button.view')"
              />
            </label>
          </td>
        </tr>
      </template>

      <template v-slot:mobile-filter>
        <button class="vca-button" @click="do_filter = true">
          {{ $t("users.list.filter") }}
        </button>
        <vca-popup
          v-if="do_filter"
          :show="do_filter"
          :title="$t('users.list.filter')"
          @close="do_filter = false"
        >
          <UsersFilter v-model="filter" />
        </vca-popup>
      </template>

      <template v-slot:mobile-header>
        <div><img width="20px" src="~@/assets/icons/sort.png" /></div>
        <div class="sortable" @click="sort('full_name')">
          <label>{{ $t("users.list.name") }} &varr;</label>
        </div>
        <div class="sortable" @click="sort('email')">
          <label>{{ $t("users.list.email") }} &varr;</label>
        </div>
      </template>

      <template v-slot:mobile-content>
        <tr
          class="vca-table-row"
          :class="{ last: index + 1 == pg.pageSize }"
          v-for="(res, index) in pg_list"
          @click="setCurrent(res)"
          :key="index"
        >
          <td class="vca-table-cell">
            <vca-column>
              <vca-row>
                <div class="vca-left vca-table-index">
                  <span class="bold">{{ res.full_name }}</span>
                </div>
                <div class="vca-left vca-table-index">
                  ({{ res.display_name }})
                </div>
                <div class="vca-right text-right vca-table-index">
                  <div>
                    {{ $t("users.list.crew_name") }}:
                    {{ res.crew.name }}
                  </div>
                </div>
              </vca-row>
              <vca-row>
                <div class="vca-left vca-table-index">
                  {{ res.email }}
                </div>
                <div class="vca-right vca-table-index">
                  {{ $t("users.list.age") }}:
                  {{ getAge(res.profile.birthdate) }}
                </div>
              </vca-row>
            </vca-column>
          </td>
        </tr>
      </template>
    </VcaTable>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import VcaTable from "@/components/utils/VcaTable";
import UsersFilter from "@/components/users/list/UsersFilter";
export default {
  name: "UsersTable",
  components: { VcaTable, UsersFilter },
  data() {
    return {
      store: "users/pg",
      do_filter: false,
      initialized: false,
      currentFilter: {
        nvm_state: "",
        active_state: "",
        pool_roles: [],
        crew_id: [],
        organisation_id: [],
      },
    };
  },
  props: {
    loaded: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.resetFilter();
  },
  watch: {
    loaded: {
      handler(value) {
        console.log("va");
        this.initialized = value;
      },
      deep: true,
    },
    "pg.currentPage": {
      handler() {
        if (this.initialized) {
          this.initialized = false;
          this.$store
            .dispatch({ type: `${this.store}/request` })
            .finally(() => {
              this.initialized = true;
            });
        }
      },
      deep: true,
    },
    "pg.pageSize": {
      handler(value) {
        if (this.initialized) {
          this.initialized = false;
          this.$store.commit(`${this.store}/pageSize`, value);
          if (
            this.pg.currentPageSize == 1 ||
            this.pg.listSize != this.pg.currentPageSize
          ) {
            this.$store
              .dispatch({ type: `${this.store}/request` })
              .finally(() => {
                this.initialized = true;
              });
          } else {
            this.initialized = true;
          }
        }
      },
      deep: true,
    },
    "pg.filterObject": {
      handler() {
        if (this.initialized) {
          this.initialized = false;
          this.$store
            .dispatch({
              type: `${this.store}/request`,
              data: { initial: true, abort: true },
            })
            .then(() => {
              this.$store.commit(`${this.store}/adjustPageSize`);
              if (this.pg.pageSize < this.pg.listSize) {
                this.$store
                  .dispatch({
                    type: `${this.store}/request`,
                    data: { initial: true, abort: true },
                  })
                  .finally(() => {
                    this.initialized = true;
                  });
              } else {
                this.initialized = true;
              }
            });
        }
      },
      deep: true,
    },
    "pg.filter": {
      handler() {
        this.$store
          .dispatch({
            type: `${this.store}/request`,
            data: { initial: true, abort: true },
          })
          .then(() => {
            this.initialized = false;
            this.$store.commit(`${this.store}/adjustPageSize`);
            if (this.pg.pageSize < this.pg.listSize) {
              this.$store
                .dispatch({
                  type: `${this.store}/request`,
                  data: { initial: true, abort: true },
                })
                .finally(() => {
                  this.initialized = true;
                });
            } else {
              this.initialized = true;
            }
          });
      },
      deep: true,
    },
  },
  computed: {
    filter: {
      set(value) {
        this.pg.filterObject = value;
      },
      get() {
        return this.pg.filterObject;
      },
    },
    ...mapState({
      pg(state, getters) {
        if (this.store) {
          return getters[`${this.store}/pagination`];
        }
      },
    }),
    ...mapGetters({
      hasSystemPermission: "user/roles/hasSystemPermission",
      pg_list: "users/pg/list",
    }),
  },
  methods: {
    resetFilter() {
      this.filter = { ...this.currentFilter };
    },
    getAge(birthdate) {
      return birthdate
        ? new Date(new Date() - new Date(birthdate * 1000)).getUTCFullYear() -
            1970
        : "-";
    },
    setCurrentEdit(value) {
      this.$router.push({ path: "/users/" + value.id });
    },
    setCurrent(value) {
      if (this.current && this.current.id == value.id) {
        this.$store.commit("users/current", null);
      } else {
        this.$store.commit("users/current", value);
      }
    },
    async sort(col) {
      await this.$store.dispatch({
        type: this.store + "/sort",
        col,
      });
      return;
      /*
            // When sorting, we set the current page to 1
            this.$store.commit(this.store + "/firstPage");

            // If we have an empty list, return an empty array
            if (!this.list) {
                return [];
            }

            // Define the sorting direction, if current sort is the same as the column to sort, then switch direction
            if (this.pg.currentSort === col) {
                this.pg.currentSortDir =
                    this.pg.currentSortDir === "asc" ? "desc" : "asc";
            } else {
                this.pg.currentSort = col;
            }

            // Sort the list depending on the column and the direction
            this.list.sort((a, b) => {
                var final_key = this.pg.currentSort;

                // Sort nested objects if sorting column contains a dot (.)
                if (col.indexOf(".") !== -1) {
                    var column = col.split(".");
                    var len = column.length;
                    var i = 0;
                    while (i < len - 1) {
                        a = a[column[i]];
                        b = b[column[i]];
                        i++;
                        final_key = column[i];
                    }
                }

                // Set zero 0 at the end
                // if (parseInt(a[final_key]) == 0) return 1;
                // if (parseInt(b[final_key]) == 0) return -1;

                // Comapre integer values
                if (
                    a[final_key] === parseInt(a[final_key], 10) ||
                    a[final_key] === parseFloat(a[final_key], 10)
                ) {
                    return this.pg.currentSortDir === "desc"
                        ? a[final_key] - b[final_key]
                        : b[final_key] - a[final_key];
                }

                // Compare string values
                let modifier = 1;
                if (this.pg.currentSortDir === "desc") modifier = -1;
                if (a[final_key].toLowerCase() < b[final_key].toLowerCase())
                    return -1 * modifier;
                if (a[final_key].toLowerCase() > b[final_key].toLowerCase())
                    return 1 * modifier;
                return 0;
            });*/
    },
  },
};
</script>
