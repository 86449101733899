<template>
  <vca-card>
    <h2>{{ $t("users.list.header") }}</h2>
    <vca-card class="shadowed vca-center">
      <AsyncUsersTable :loaded="initialized" />
      <vca-popup
        v-if="current != null"
        :show="current != null"
        :title="$t('users.popup.edit', { 0: current.display_name })"
        @close="close()"
      >
        <UserProfile />
      </vca-popup>
    </vca-card>
  </vca-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import AsyncUsersTable from "@/components/users/AsyncUsersTable";
import UserProfile from "@/components/users/UserProfile";
export default {
  name: "UsersList",
  components: { AsyncUsersTable, UserProfile },
  data() {
    return {
      store: "users/pg",
      initialized: false,
    };
  },
  mounted() {
    this.current = null;
    this.$store.dispatch({ type: "refresh" }).then(() => {
      this.$store.commit("users/pg/route", "users");
      this.$store.commit("users/pg/context", this.store);
      if (this.hasSystemPermission(["employee", "pool_employee"])) {
        this.$store.dispatch("organisations/list");

        if (!this.hasSystemPermission("admin") && this.user.organisation_id) {
          this.filter.organisation_id = [this.user.organisation_id];
        }
        this.$store
          .dispatch({
            type: "users/pg/request",
            data: { initial: true, abort: true },
          })
          .finally(() => {
            console.log("init");
            this.initialized = true;
          });
        this.$store.dispatch({ type: "crews/public" });
      } else if (
        this.hasPoolPermission([
          "operation",
          "network",
          "finance",
          "education",
          "socialmedia",
          "awareness",
          "asp",
          "other",
        ])
      ) {
        this.$store.commit("users/pg/filterObject", {
          crew_id: this.user.crew.crew_id,
        });
        this.$store
          .dispatch({
            type: "users/pg/request",
            data: { initial: true, abort: true },
          })
          .finally(() => {
            this.initialized = true;
          });
        this.$store.dispatch({ type: "crews/public" });
      }
    });
  },
  computed: {
    ...mapGetters({
      user: "user/current",
      hasPoolPermission: "user/roles/hasPoolPermission",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
    current: {
      set(value) {
        this.$store.state.users.current = value;
      },
      get() {
        return this.$store.state.users.current;
      },
    },
    filter: {
      set(value) {
        this.pg.filterObject = value;
      },
      get() {
        return this.pg.filterObject;
      },
    },
    ...mapState({
      pg(state, getters) {
        if (this.store) {
          return getters[`${this.store}/pagination`];
        }
      },
    }),
  },
  methods: {
    close() {
      this.$store.commit("users/clean_current");
    },
  },
};
</script>
